/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs";
import { Turbo } from "@hotwired/turbo-rails";

import ahoy from "ahoy.js";

// Make accessible for Electron and Mobile adapters
window.Rails = Rails;
window.Turbo = Turbo;

require("@rails/activestorage").start();

// ActionCable Channels
import "./channels";

// Web Components
import "./components";

// Stimulus controllers
import "./controllers";

// Jumpstart Pro & other Functionality
import "./src/**/*";
import "./src/srcset-defibrillator";
require("local-time").start();

// Start Rails UJS
Rails.start();

document.addEventListener("turbo:load", () => {
  document.querySelector("html").classList.add("js");
});
