// Custom TailwindCSS modals for confirm dialogs

const Rails = require("@rails/ujs");

// Cache a copy of the old Rails.confirm since we'll override it when the modal opens
const old_confirm = Rails.confirm;

// Elements we want to listen to for data-confirm
const elements = [
  "a[data-confirm]",
  "button[data-confirm]",
  "input[type=submit][data-confirm]",
];

const createConfirmModal = (element) => {
  var id = "confirm-modal-" + String(Math.random()).slice(2, -1);
  var header = element.dataset.confirmHeader || "Confirm";
  var confirmButtonLabel = element.dataset.confirmButtonLabel || "Yes, Do It";
  var cancelButtonLabel = element.dataset.cancelButtonLabel || "Nevermind";
  var confirm = element.dataset.confirm;

  var content = `
    <section id="${id}" class="modal-dialog confirmation stack">
      <header class="header">
        <h4>${header}</h4>
      </header>
      <div class="content stack">
        <p>${confirm}</p>

        <div class="actions cluster-tight cluster-spread">
          <button data-behavior="commit" class="btn btn-danger">${confirmButtonLabel}</button>
          <button data-behavior="cancel" class="btn btn-secondary btn-outline btn-small">${cancelButtonLabel}</button>
        </div>
      </div>
    </section>
    <div class="modal-dialog-underlay"></div>
  `;

  document.body.insertAdjacentHTML("beforeend", content);

  const modal = document.getElementById(id);
  const underlay = modal.nextElementSibling;
  element.dataset.confirmModal = `#${id}`;

  modal.addEventListener("keyup", (event) => {
    if (event.key === "Escape") {
      event.preventDefault();
      element.removeAttribute("data-confirm-modal");
      modal.remove();
    }
  });

  modal
    .querySelector("[data-behavior='cancel']")
    .addEventListener("click", (event) => {
      event.preventDefault();
      element.removeAttribute("data-confirm-modal");
      if (element.dataset.resetWithoutConfirmation) {
        try {
          element.form.reset();
        } catch (e) {}
      }
      modal.remove();
      underlay.remove();
    });
  modal
    .querySelector("[data-behavior='commit']")
    .addEventListener("click", (event) => {
      event.preventDefault();

      // Allow the confirm to go through
      Rails.confirm = () => {
        return true;
      };

      // Click the link again
      element.click();

      // Remove the confirm attribute and modal
      element.removeAttribute("data-confirm-modal");
      Rails.confirm = old_confirm;

      modal.remove();
      underlay.remove();

      if (element.dataset.closeModal) {
        const modal = document.querySelector(
          `[id='${element.dataset.closeModal}'] button.close`
        );
        if (modal) modal.click();
      }
    });

  modal.querySelector("[data-behavior='commit']").focus();
  return modal;
};

// Checks if confirm modal is open
const confirmModalOpen = (element) => {
  return !!element.dataset.confirmModal;
};

const handleConfirm = (event) => {
  // If there is a modal open, let the second confirm click through
  if (confirmModalOpen(event.target)) {
    return true;

    // First click, we need to spawn the modal
  } else {
    createConfirmModal(event.target);
    return false;
  }
};

// When a Rails confirm event fires, we'll handle it
Rails.delegate(document, elements.join(", "), "confirm", handleConfirm);
